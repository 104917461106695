import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "Register" */ '../views/RegisterView.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword')
    },
    {
        path: '/scanner',
        name: 'scanner',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ScannerView" */ '../views/ScannerView')
    },
    {
        path: '/',
        name: 'reports',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ReportView" */ '../views/ReportView.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ProfileView" */ '../views/ProfileView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (useAuthStore().isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.hideForAuth)) {
        if (useAuthStore().isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});


export default router
