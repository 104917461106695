<template>
  <div class="container-footer">
    <div class="container-single-img container-single-img-first">
      <img @click="goToNameRouter('reports')" v-show="$route.name !== 'reports'"
           v-lazy="{ src: require('../../assets/shared/list.svg')}" src="" alt="">
      <img v-show="$route.name === 'reports'" src="" v-lazy="{ src: require('../../assets/shared/list_active.svg')}"
           alt="">
      <span :class="{'text-img-active': $route.name === 'reports'}" class="text-img">{{ $t(`translationDictionary.footer.${'transaction'}`) }}</span>
    </div>
    <div class="container-single-img">
      <img style="width: 25px!important;" @click="goToNameRouter('scanner')" v-show="$route.name !== 'scanner'"
           v-lazy="{ src: require('../../assets/shared/qr-code-scan.svg')}" src="" alt="">
      <img style="width: 25px!important;" v-show="$route.name === 'scanner'" src="../../assets/shared/qr-code-scan_active.svg"
           v-lazy="{ src: require('../../assets/shared/qr-code-scan_active.svg')}" alt="">
      <span :class="{'text-img-active': $route.name === 'scanner'}" class="text-img">Scanner</span>
    </div>
    <div class="container-single-img container-single-img-last">
      <img @click="goToNameRouter('profile')" v-show="$route.name !== 'profile'" src=""
           v-lazy="{ src: require('../../assets/shared/person.svg')}" alt="">
      <img v-show="$route.name === 'profile'" src="" v-lazy="{ src: require('../../assets/shared/person_active.svg')}"
           alt="">
      <span :class="{'text-img-active': $route.name === 'profile'}" class="text-img">{{ $t(`translationDictionary.footer.${'profile'}`) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentFooter',
  setup() {
    return {}
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    goToNameRouter(name) {
      this.$router.push({name: name}).catch(() => {
      })
    },
  },
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }

}
</script>

<style scoped>

.container-footer img {
  margin: auto auto 0px auto;
  width: 30px;
  height: 30px;
}

.text-img-active.text-img {
  color: var(--primary-color)
}

.text-img {
  color: #4D535E;
  font-size: 14px;
  margin-bottom: 10px;
}

.container-single-img {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 80px;
  align-items: center;
}

.container-footer {
  display: flex;
  min-height: 80px;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  border-top: 1px solid #A1A1A1;
  background: var(--white-color);
  backdrop-filter: blur(2px);
  align-items: center;
  margin-top: auto;
}

</style>