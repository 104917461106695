import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import axios from "axios";
import BootstrapVueNext from 'bootstrap-vue-next'
import {createPinia} from 'pinia'
import {createPersistedStatePlugin} from 'pinia-plugin-persistedstate-2'
import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import {createI18n} from 'vue-i18n';
import VueLazyLoad from 'vue3-lazyload'
import {langs} from "@/langs";
// eslint-disable-next-line no-unused-vars
import {languages} from "@/langs/languages";
import {useAuthStore} from "@/stores/auth";
import moment from "moment";

moment.locale('it')
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST

const i18n = createI18n({
    legacy: false,
    locale: languages[navigator.language],
    fallbackLocale: 'en',
    messages: langs
});


axios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.status === 404) {
            useAuthStore().$reset()
            router.push('/').catch(() => {
            })
        }
        if (error.response.status === 401) {
            if (error.response.data.code !== "token_not_valid") {
                useAuthStore().$reset()
                let string_split
                Object.keys(error.response.data).forEach(el => {
                    string_split = el.split('-')
                    if (string_split[1] === 'expired_token') {
                        useAuthStore().errorToken = 'Sessione scaduta'
                    } else if (string_split[1] === 'invalid_token') {
                        useAuthStore().errorToken = 'Rieffettuare il login'
                    } else if (el === 'code-invalid_code') {
                        useAuthStore().errorToken = error.response.data['code-invalid_code']
                    } else if (el === 'authorization-user_inactive') {
                        useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                    } else if (el === 'authorization-expired_token') {
                        useAuthStore().errorToken = error.response.data['authorization-expired_token']
                    } else if (el === 'detail') {
                        useAuthStore().errorToken = 'Qualcosa non ha funzionato oppure non sei autorizzato'
                    }
                })
                router.push('/login').catch(() => {
                })
            }
            return Promise.reject(error);
        }
    }
    // if (error.message === 'Network Error') {
    //     useApiSettingStore().overlayModal = false
    //     useApiSettingStore().errorNetwork = true
    // }
    return Promise.reject(error);
})

export function tokenUpdate(err,store,state) {
    delete axios.defaults.headers['Authorization']
    useAuthStore().getRefreshToken({
        refresh: useAuthStore().refreshTokenGetters
    })
        .then(res => {
            useAuthStore().bearerToken = res.data.access
            axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.access
            err.config.headers['Authorization'] = 'Bearer ' + res.data.access

            axios.request(err.config)
                .then(resp => {
                    if (state)
                        store[state] = resp.data
                })
                .catch(() => {})

        })
        .catch(() => {
            useAuthStore().$reset()
            router.push({name: 'login'}).catch(() => {})
        })
}

const pinia = createPinia()
const app = createApp(App)
app.use(i18n)
app.use(VueLazyLoad)
app.use(router)
app.use(BootstrapVueNext)
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')