import axios from 'axios'
import {defineStore} from 'pinia'


export const useAuthStore = defineStore({
    id: 'auth',


    state: () => ({
        user: null,
        bearerToken: null,
        refreshToken: null,
        errorToken: '',
        error: ''
    }),
    actions: {
        login(payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/token/',payload)
                    .then(res => {
                        this.user = res.data
                        this.bearerToken = res.data.access
                        this.refreshToken = res.data.refresh
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getRefreshToken(payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/token/refresh/',payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        isAuthenticated: state => {
            return !!state.user
        },
        userGetters: state => {
            return state.user
        },
        errorGetters: state => {
            return state.error
        },
        errorTokenGetters: state => {
            return state.errorToken
        },
        bearerTokenGetters: state => {
            return state.bearerToken
        },
        refreshTokenGetters: state => {
            return state.refreshToken
        }
    }
})


