<template>
  <div id="container-app" class="container-app">
    <router-view></router-view>
    <ComponentFooter v-if="storeAuth.isAuthenticated"></ComponentFooter>
  </div>
</template>

<script>


import ComponentFooter from "@/components/footer/ComponentFooter.vue";
import {useAuthStore} from "@/stores/auth";
import axios from "axios";
import {useUtilsStore} from "@/stores/utils";
import {languages} from "@/langs/languages";

export default {
  name: 'App',
  components: {ComponentFooter},
  setup() {
    const storeAuth = useAuthStore()
    return {
      storeAuth
    }
  },
  data() {
    return {
      currentLang: 'it'
    }
  },
  created() {
    useUtilsStore().currentLanguage = languages[navigator.language]
  },
  computed: {},
  mounted() {
    // window.addEventListener("resize", this.handleResize)

    this.handleChangeOrientation()
    window.addEventListener('orientationchange', this.handleChangeOrientation)
    window.addEventListener('resize', this.handleChangeOrientation)

    if (useAuthStore().isAuthenticated) {
      axios.defaults.headers['Authorization'] = 'Bearer ' + useAuthStore().bearerTokenGetters
    }

  },
  methods: {
    handleChangeOrientation() {
      let vh = window.innerHeight * 0.01;
      let height = vh * 100
      document.getElementById('app').style.height = height + 'px'
    },
  },
  updated() {
    this.handleChangeOrientation()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleChangeOrientation)
    window.removeEventListener('orientationchange', this.handleChangeOrientation)
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
}
</style>
