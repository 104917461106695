import {defineStore} from 'pinia'


export const useUtilsStore = defineStore({
    id: 'utils',


    state: () => ({
        currentLanguage: navigator.language,
        errorCode: null
    }),
    actions: {

    },

    getters: {
        currentLanguageGetters: state => {
            return state.currentLanguage
        },
        errorCodeGetters: state => {
            return state.errorCode
        }
    }
})


